import React, { useContext, useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { Spinner } from "react-bootstrap";
import { fetchConsultations, fetchInitiations, fetchRecommendations } from "../http/informationProcessAPI";
import { INITIATION_ROUTE, PUBLIC_CONSULTATIONS_ROUTE, RECOMENDATIONS_ROUTE } from "../utils/consts";
import { Link } from "react-router-dom";

const DecisionProcess = () => {
	const { informationProcess } = useContext(Context);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchConsultations().then((data) => {
			informationProcess.setConsultation(data);
		});
		fetchInitiations().then((data) => {
			informationProcess.setInitiation(data);
		});
		fetchRecommendations()
			.then((data) => {
				informationProcess.setRecommendations(data);
			})
			.finally(() => setLoading(false));
	}, [informationProcess]);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-md-row flex-column justify-content-around px-4 border-bottom">
			<div className="d-flex flex-column px-5 py-4 col-md-8">
				<div className="block-title pb-4">
					<span>Procesul decizional</span>
				</div>
				<Tabs
					defaultActiveKey="consultation"
					id="uncontrolled-tab-example"
					className="mb-3 text-primary fw-bold"
				>
					<Tab eventKey="consultation" title="Consultare publică">
						{informationProcess.consultation.count > 0
							? informationProcess.consultation.rows.map((item, idx) => (
									<Link
										key={idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none"
										to={PUBLIC_CONSULTATIONS_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-1 text-primary">{item.title}</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab eventKey="initiation" title="Inițiere proiect">
						{informationProcess.initiation.count > 0
							? informationProcess.initiation.rows.map((item, idx) => (
									<Link
										key={idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none"
										to={INITIATION_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-1 text-primary">{item.title}</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
					<Tab eventKey="recommendations" title="Sinteza recomandărilor">
						{informationProcess.recommendations.count > 0
							? informationProcess.recommendations.rows.map((item, idx) => (
									<Link
										key={idx}
										className="d-flex flex-column justify-content-start py-3 text-decoration-none"
										to={RECOMENDATIONS_ROUTE + "/" + item.id}
									>
										<span className="fw-bold font-1 text-primary">{item.title}</span>
										<div className="d-flex justify-content-start py-2">
											<span className="border rounded border-primary px-2 text-primary">
												{item.date}
											</span>
										</div>
									</Link>
							  ))
							: null}
					</Tab>
				</Tabs>
			</div>
			<Calendar />
		</div>
	);
};

export default DecisionProcess;
