import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const createReferences = async (reference) => {
	const { data } = await $authHost.post("api/references", reference);
	return data;
};

export const fetchReferences = async () => {
	const { data } = await $host.get("api/references");
	return data;
};

export const fetchOneReferences = async (id) => {
	const { data } = await $host.get("api/references/" + id);
	return data;
};

export const removeOneReferences = async (id) => {
	const { data } = await $host.post("api/references/" + id);
	return data;
};

export const updateOneReferences = async (processData, id) => {
	const { data } = await $host.post("api/references/u/" + id, processData);
	return data;
};
