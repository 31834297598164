import { useContext, useEffect, useState } from "react";
import { Image, Carousel } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Spinner } from "react-bootstrap";
import { fetchUsefulLinks } from "../http/usefulLinksAPI";
import { Link } from "react-router-dom";

const Useful = observer(() => {
	const { usefulLinks } = useContext(Context);

	const [index, setIndex] = useState(0);
	const [loading, setLoading] = useState(true);

	const normalizeUsefulLinks = (list) => {
		const subarrays = [];
		for (let i = 0; i < list.length; i += 4) {
			const chunk = list.slice(i, i + 4);
			subarrays.push(chunk);
		}
		return usefulLinks.setLinks(subarrays);
	};

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	useEffect(() => {
		fetchUsefulLinks()
			.then((data) => {
				normalizeUsefulLinks(data);
			})
			.finally(() => setLoading(false));
	}, [usefulLinks]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='useful-block d-flex flex-column justify-content-around py-4 px-xl-5 px-2 border-bottom'>
			<div className='block-title pb-3'>
				<span>Lincuri utile</span>
			</div>
			{/* <CustomCarousel show={4}>
				{usefulLinks.links.map((item, idx) => (
					<div className='d-flex shadow-sm flex-row align-items-center justify-content-center p-2 m-2 text-primary rounded col-md-4 col-12'>
						<Image
							src={gov_logo}
							className='gov-logo-useful d-none'
							style={{
								width: "4rem",
								height: "auto",
							}}
						/>
						<span className='text-wrap ps-4 fw-bold'>Viceprim-ministru pentru Reintegrare</span>
					</div>
				))}
			</CustomCarousel> */}
			<Carousel
				activeIndex={index}
				onSelect={handleSelect}
				data-bs-theme='dark'
				indicators={false}>
				{usefulLinks.links.map((item, idx) => (
					<Carousel.Item key={"useful-link-cont-" + idx}>
						<div className='d-flex flex-xl-row flex-column flex-wrap justify-content-center mx-xl-5 mx-2'>
							{item.map((sub_item, sub_idx) => (
								<div
									key={"useful-link-" + sub_idx}
									className='d-flex shadow-sm flex-row align-items-center text-center p-2 my-2 text-primary rounded mx-xl-4'>
									<Image
										src={process.env.REACT_APP_API_URL + sub_item.img}
										className='gov-logo-useful'
										style={{
											width: "4rem",
											height: "auto",
										}}
									/>
									<a
										href={sub_item.link}
										className='text-wrap text-decoration-none text-center font-1 ps-4 text-primary fw-bold w-100'>
										{sub_item.title}
									</a>
								</div>
							))}
						</div>
					</Carousel.Item>
				))}
				{/* <Carousel.Item>
					<div className='d-flex flex-md-row flex-column flex-wrap justify-content-center mx-5'>
						<div className='d-flex shadow-sm flex-row align-items-center p-2 my-2 text-primary rounded mx-2'>
							<Image
								src={gov_logo}
								className='gov-logo-useful'
								style={{
									width: "4rem",
									height: "auto",
								}}
							/>
							<span className='text-wrap ps-4 fw-bold'>Viceprim-ministru pentru Reintegrare</span>
						</div>
						<div className='d-flex shadow-sm flex-row align-items-center p-2 my-2 text-primary rounded mx-2'>
							<Image
								src={gov_logo}
								className='gov-logo-useful'
								style={{
									width: "4rem",
									height: "auto",
								}}
							/>
							<span className='text-wrap ps-4 fw-bold'>Viceprim-ministru pentru Reintegrare</span>
						</div>
						<div className='d-flex shadow-sm flex-row align-items-center p-2 my-2 text-primary rounded mx-2'>
							<Image
								src={gov_logo}
								className='gov-logo-useful'
								style={{
									width: "4rem",
									height: "auto",
								}}
							/>
							<span className='text-wrap ps-4 fw-bold'>Viceprim-ministru pentru Reintegrare</span>
						</div>
						<div className='d-flex shadow-sm flex-row align-items-center p-2 my-2 text-primary rounded mx-2'>
							<Image
								src={gov_logo}
								className='gov-logo-useful'
								style={{
									width: "4rem",
									height: "auto",
								}}
							/>
							<span className='text-wrap ps-4 fw-bold'>Viceprim-ministru pentru Reintegrare</span>
						</div>
					</div>
				</Carousel.Item> */}
			</Carousel>
		</div>
	);
});

export default Useful;
