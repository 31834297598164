import { makeAutoObservable } from "mobx";

export default class ActivitiesStore {
    constructor() {
        this._mainBanner = [];
        this._mayorMessage = [];
        this._citizensHearingProgram = [];
        this._projects = [];
        makeAutoObservable(this);
    }

    setMainBanner(mainBanner) {
        this._mainBanner = mainBanner;
    }

    get mainBanner() {
        return this._mainBanner;
    }

    setMayorMessage(mayorMessage) {
        this._mayorMessage = mayorMessage;
    }

    get mayorMessage() {
        return this._mayorMessage;
    }

    setCitizensHearingProgram(citizensHearingProgram) {
        this._citizensHearingProgram = citizensHearingProgram;
    }

    get citizensHearingProgram() {
        return this._citizensHearingProgram;
    }

    setProjects(projects) {
        this._projects = projects;
    }

    get projects() {
        return this._projects;
    }
}
