import { makeAutoObservable } from "mobx";

export default class AdministrationStore {
	constructor() {
		this._meetings = [
			// {
			//   id: 1,
			//   title:
			//     "Regulamentul privind constituirea și funcționarea Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 2,
			//   title:
			//     "Regulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		this._verbalProcedure = [
			// {
			//   id: 1,
			//   title:
			//     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 2,
			//   title:
			//     "Regulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		this._specialCommitteesMeets = [
			// {
			//   id: 1,
			//   title:
			//     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
			// {
			//   id: 2,
			//   title:
			//     "BRegulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		// Proiecte de decizii
		makeAutoObservable(this);
	}

	setMeetings(meetings) {
		this._meetings = meetings;
	}
	get meetings() {
		return this._meetings;
	}

	setVerbalProcedure(verbalProcedure) {
		this._verbalProcedure = verbalProcedure;
	}
	get verbalProcedure() {
		return this._verbalProcedure;
	}

	setSpecialCommitteesMeets(specialCommitteesMeets) {
		this._specialCommitteesMeets = specialCommitteesMeets;
	}
	get specialCommitteesMeets() {
		return this._specialCommitteesMeets;
	}
}
