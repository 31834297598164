import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { createExtraordinaryCommissions } from "../../../http/resourcesServicesAPI";

const AddExtraordinaryCommissionsModal = ({ show, onHide, selectedTab }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [author, setAuthor] = useState("");
	const [img, setImg] = useState([null]);

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("author", author);
		switch (selectedTab) {
			case "Comisii Extraordinare":
				createExtraordinaryCommissions(formData).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
				<Form.Control
					aria-label='Title'
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
					}}
				/>
				<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
				<Form.Control
					aria-label='Title'
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
					}}
				/>
				<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
				<Form.Control
					aria-label='Title'
					value={author}
					onChange={(e) => {
						setAuthor(e.target.value);
					}}
				/>
				<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
				<Form.Control
					type={"file"}
					onChange={selectFile}
					aria-label='PageTitle'
					aria-describedby='basic-addon'
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						addItem();
						onHide();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddExtraordinaryCommissionsModal;
