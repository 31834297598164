import { $authHost, $host } from "./index";

// export const createEx = async (ex) => {
// 	const { data } = await $authHost.post("api/ex", ex);
// 	return data;
// };

// export const fetchEX = async () => {
// 	const { data } = await $host.get("api/ex");
// 	return data;
// };

export const createContactInformation = async (contactInformation) => {
	const { data } = await $authHost.post("api/contact_information", contactInformation);
	return data;
};

export const fetchContactInformation = async () => {
	const { data } = await $host.get("api/contact_information");
	return data;
};

export const fetchOneContactInformation = async (id) => {
	const { data } = await $host.get("api/contact_information/" + id);
	return data;
};

export const updateOneContactInformation = async (passedData, id) => {
	const { data } = await $host.post("api/contact_information/u/" + id, passedData);
	return data;
};
