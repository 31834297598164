import { useEffect, useState, useContext } from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchOneContactInformation, updateOneContactInformation } from "../../http/contactInformationAPI";
import { Spinner } from "react-bootstrap";

const NavBarFooterAdmin = observer(() => {
	const { contactInformation } = useContext(Context);
	const [pageTitle, setPageTitle] = useState("");
	const [orgLogo, setOrgLogo] = useState([null]);
	const [orgRegion, setOrgRegion] = useState("");
	const [orgName, setOrgName] = useState("");
	const [orgPhone, setOrgPhone] = useState("");
	const [orgEmail, setOrgEmail] = useState("");
	const [orgAddress, setOrgAddress] = useState("");
	const [orgSchedule, setOrgSchedule] = useState("");
	const [orgCopyright, setOrgCopyright] = useState("");
	const [orgUpdated, setOrgUpdated] = useState("");
	const [orgRedlinePhone, setOrgRedlinePhone] = useState("");
	const [orgRedlineEmail, setOrgRedlineEmail] = useState("");
	const [facebookLink, setFacebookLink] = useState("");
	const [telegramLink, setTelegramLink] = useState("");
	const [youtubeLink, setYoutubeLink] = useState("");

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchOneContactInformation(1)
			.then((data) => {
				contactInformation.setContactInformation(data);
			})
			.finally(() => setLoading(false));
	}, []);

	const selectFile = (e) => {
		setOrgLogo(e.target.files[0]);
	};

	const updateContactInfo = () => {
		const formData = new FormData();
		if (pageTitle !== "") formData.append("page_title", pageTitle);
		if (orgLogo) formData.append("org_logo", orgLogo);
		if (orgRegion !== "") formData.append("org_region", orgRegion);
		if (orgName !== "") formData.append("org_name", orgName);
		if (orgPhone !== "") formData.append("org_phone", orgPhone);
		if (orgEmail !== "") formData.append("org_email", orgEmail);
		if (orgAddress !== "") formData.append("org_address", orgAddress);
		if (orgSchedule !== "") formData.append("org_schedule", orgSchedule);
		if (orgCopyright !== "") formData.append("org_copyright", orgCopyright);
		if (orgUpdated !== "") formData.append("org_updated", orgUpdated);
		if (orgRedlinePhone !== "") formData.append("org_redline_phone", orgRedlinePhone);
		if (orgRedlineEmail !== "") formData.append("org_redline_email", orgRedlineEmail);
		if (facebookLink !== "") formData.append("facebook_link", facebookLink);
		if (telegramLink !== "") formData.append("telegram_link", telegramLink);
		if (youtubeLink !== "") formData.append("youtube_link", youtubeLink);
		updateOneContactInformation(formData, 1).then(() => window.location.reload());
	};

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div
				className='d-flex justify-content-center align-items-center font-2 fw-bold text-primary shadow-sm ps-3'
				style={{ height: 70 }}>
				<span>Meniu de Navigare Și Footer</span>
			</div>
			{contactInformation.contactInformation ? (
				<div
					className='px-5 mt-4'
					// style={{ maxHeight: 720, overflow: "auto" }}
				>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon'>Denumirea Paginii</InputGroup.Text>
						<Form.Control
							value={pageTitle}
							onChange={(e) => setPageTitle(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.page_title
								contactInformation.contactInformation.page_title
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon1'>Link către Facebook</InputGroup.Text>
						<Form.Control
							value={facebookLink}
							onChange={(e) => setFacebookLink(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.facebook_link
								contactInformation.contactInformation.facebook_link
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon1'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon2'>Link către Telegram</InputGroup.Text>
						<Form.Control
							value={telegramLink}
							onChange={(e) => setTelegramLink(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.telegram_link
								contactInformation.contactInformation.telegram_link
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon2'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon3'>Link către Youtube</InputGroup.Text>
						<Form.Control
							value={youtubeLink}
							onChange={(e) => setYoutubeLink(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.youtube_link
								contactInformation.contactInformation.youtube_link
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon3'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon4'>E-Mail</InputGroup.Text>
						<Form.Control
							value={orgEmail}
							onChange={(e) => setOrgEmail(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_email
								contactInformation.contactInformation.org_email
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon4'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon5'>Numǎrul de Telefon</InputGroup.Text>
						<Form.Control
							value={orgPhone}
							onChange={(e) => setOrgPhone(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_phone
								contactInformation.contactInformation.org_phone
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon5'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon'>Logo-ul Site-lui</InputGroup.Text>
						<Form.Control
							type={"file"}
							onChange={selectFile}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_logo
								contactInformation.contactInformation.org_logo
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon1'>Regionul Organizației</InputGroup.Text>
						<Form.Control
							value={orgRegion}
							onChange={(e) => setOrgRegion(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_region
								contactInformation.contactInformation.org_region
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon1'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon2'>Denumirea Organizației</InputGroup.Text>
						<Form.Control
							value={orgName}
							onChange={(e) => setOrgName(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_name
								contactInformation.contactInformation.org_name
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon2'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon3'>Adresa Organizației</InputGroup.Text>
						<Form.Control
							value={orgAddress}
							onChange={(e) => setOrgAddress(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_address
								contactInformation.contactInformation.org_address
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon3'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon4'>Program de Lucru</InputGroup.Text>
						<Form.Control
							value={orgSchedule}
							onChange={(e) => setOrgSchedule(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_schedule
								contactInformation.contactInformation.org_schedule
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon4'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon5'>Linia Verde E-Mail</InputGroup.Text>
						<Form.Control
							value={orgRedlineEmail}
							onChange={(e) => setOrgRedlineEmail(e.target.value)}
							// placeholder={"Actual: " + contactInformation.contactInformation.org_redline_email}
							placeholder={contactInformation.contactInformation.org_redline_email}
							aria-label='PageTitle'
							aria-describedby='basic-addon5'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon'>Linia Verde Numǎrul de Telefon</InputGroup.Text>
						<Form.Control
							value={orgRedlinePhone}
							onChange={(e) => setOrgRedlinePhone(e.target.value)}
							// placeholder={"Actual: " + contactInformation.contactInformation.org_redline_phone}
							placeholder={contactInformation.contactInformation.org_redline_phone}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon2'>Copyrights</InputGroup.Text>
						<Form.Control
							value={orgCopyright}
							onChange={(e) => setOrgCopyright(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_copyright
								contactInformation.contactInformation.org_copyright
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon2'
						/>
					</InputGroup>
					<InputGroup className='mb-3'>
						<InputGroup.Text id='basic-addon3'>Ultima Actualizare</InputGroup.Text>
						<Form.Control
							value={orgUpdated}
							onChange={(e) => setOrgUpdated(e.target.value)}
							placeholder={
								// "Actual: " + contactInformation.contactInformation.org_updated
								contactInformation.contactInformation.org_updated
							}
							aria-label='PageTitle'
							aria-describedby='basic-addon3'
						/>
					</InputGroup>
					<div className='d-flex flex-md-row flex-column align-items-center justify-content-around pb-3'>
						{/* <Button variant='secondary'>Anulează</Button> */}
						<Button
							variant='primary'
							onClick={() => updateContactInfo()}>
							Confirmare
						</Button>
					</div>
				</div>
			) : null}
			{/* <NavBarAdmin
				newsType={"cityNews"}
				show={show}
				onHide={() => setShow(false)}
			/> */}
		</div>
	);
});

export default NavBarFooterAdmin;
