import { useState, useEffect, useRef } from "react";
import Calendar from "../../components/Calendar";
import { Image, Carousel } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneCityNews } from "../../http/NewsAPI";
import parse from "html-react-parser";
import ShareButton from "../../components/UI/ShareButton";

const CityNewsPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchOneCityNews(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4'>
				{data ? (
					<div
						className='d-flex flex-column col-xl-8 col-12 px-xl-4 px-md-4 px-1'
						ref={printRef}>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center'>
							<Carousel
								className='col-12'
								fade>
								{data.city_news_files.length > 0 ? (
									data.city_news_files.length > 1 ? (
										data.city_news_files.map((item, idx) => (
											<Carousel.Item
												key={idx}
												className='h-100 rounded'
												style={{
													background: `url(${
														process.env.REACT_APP_API_URL + item.img
													})  center center/cover no-repeat`,
													minHeight: "55vh",
													backgroundSize: "cover !important",
													height: "100%",
												}}>
												<Carousel.Caption>
													{/* <h3 className='text-start'>{item.title}</h3> */}
													<span
														href='#'
														className='d-flex justify-content-start align-items-center my-3 text-decoration-none text-white ps-4'>
														{item.img_description} {/* <i className="bi bi-arrow-right ps-3 icon-2" /> */}
													</span>
												</Carousel.Caption>
											</Carousel.Item>
										))
									) : (
										<Image
											className='rounded'
											style={{
												maxHeight: "50vh",
												width: "auto",
												aspectRatio: 1,
												objectFit: "contain",
											}}
											src={process.env.REACT_APP_API_URL + data.city_news_files[0].img}
										/>
									)
								) : null}
							</Carousel>
							{data.article ? <div className='text-justify font-2 pt-2 px-3 px-md-0'>{parse(data.article)}</div> : null}
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-xl-5 px-3'>
							{data.author ? (
								<span className='border border-primary rounded text-primary my-sm-2 p-2 fw-bold'>Autor: {data.author}</span>
							) : null}
							{data.date ? <span className='border border-primary rounded text-primary p-2 fw-bold'>{data.date}</span> : null}
							<div className='d-flex flex-row align-items-center justify-content-end'>
								<i
									role='button'
									onClick={() => handlePrint()}
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default CityNewsPage;
