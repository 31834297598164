import { useEffect, useState, useContext } from "react";
import {
  Form,
  Spinner,
  Accordion,
  Card,
  Image,
  useAccordionButton,
  Tabs,
  Tab,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import parse from "html-react-parser";
import { SOCIAL_ECONOMIC_INDICATORS_ROUTE } from "../../utils/consts";
import {
  fetchDistrictBudget,
  fetchSocialEconomicIndicators,
  fetchTourismMarketingPlan,
  removeOneDistrictBudget,
  removeOneDistrictBudgetFile,
  removeOneSocialEconomicIndicators,
  removeOneSocialEconomicIndicatorsFile,
  removeOneTourismMarketingPlan,
  removeOneTourismMarketingPlanFile,
  updateOneDistrictBudget,
  updateOneSocialEconomicIndicators,
  updateOneTourismMarketingPlan,
} from "../../http/navLinksAPI";
import AddEconomyModal from "./Modals/AddEconomyModal";

const EconomyAdmin = observer(() => {
  const { economy } = useContext(Context);
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    "Indicatorii Social-Economici"
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState([null]);
  const [files, setFiles] = useState([null]);

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
      <button
        className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  };

  const editItem = (data) => {
    const id = data.id;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("img", img);
    for (const file of files) {
      formData.append("files", file);
    }
    switch (selectedTab) {
      case "Indicatorii Social-Economici":
        updateOneSocialEconomicIndicators(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Bugetul Raional":
        updateOneDistrictBudget(formData, id).then(() =>
          window.location.reload()
        );
        break;
      case "Planul de Marketing in Turism":
        updateOneTourismMarketingPlan(formData, id).then(() =>
          window.location.reload()
        );
    }
  };

  const removeItem = (item) => {
    const id = item.id;
    switch (selectedTab) {
      case "Indicatorii Social-Economici":
        removeOneSocialEconomicIndicators(id).then(() =>
          window.location.reload()
        );
        break;
      case "Bugetul Raional":
        removeOneDistrictBudget(id).then(() => window.location.reload());
        break;
      case "Planul de Marketing in Turism":
        removeOneTourismMarketingPlan(id).then(() => window.location.reload());
    }
  };

  const removeItemFile = (item) => {
    const id = item.id;
    switch (selectedTab) {
      case "Indicatorii Social-Economici":
        removeOneSocialEconomicIndicatorsFile(id).then(() =>
          window.location.reload()
        );
        break;
      case "Bugetul Raional":
        removeOneDistrictBudgetFile(id).then(() => window.location.reload());
        break;
      case "Planul de Marketing in Turism":
        removeOneTourismMarketingPlanFile(id).then(() =>
          window.location.reload()
        );
    }
  };

  const selectFile = (e) => {
    setImg(e.target.files[0]);
  };

  const selectMultipleFiles = (e) => {
    setFiles(e.target.files);
  };

  useEffect(() => {
    fetchSocialEconomicIndicators().then((data) => {
      economy.setIndicators(data);
    });
    fetchDistrictBudget().then((data) => {
      economy.setBudget(data);
    });
    fetchTourismMarketingPlan()
      .then((data) => {
        economy.setTourismMarketing(data);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <Spinner animation={"grow"} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column bg-white r-corner-radius w-100">
      <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
        <span>Economie</span>
        <button
          className="btn btn-primary r-u-corner-radius"
          style={{ height: 70, width: 70 }}
          onClick={() => setShow(true)}
        >
          <i className="bi bi-plus-circle icon-2" />
        </button>
      </div>
      <div className="d-flex flex-column px-2 pt-3">
        <Tabs
          defaultActiveKey="Indicatorii Social-Economici"
          id="uncontrolled-tab-example"
          className="d-flex justify-content-center mb-3 text-primary fw-bold"
          onSelect={(e) => setSelectedTab(e)}
        >
          <Tab
            eventKey="Indicatorii Social-Economici"
            title="Indicatorii Social-Economici"
          >
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {economy.indicators.count > 0
                  ? economy.indicators.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  href={
                                    SOCIAL_ECONOMIC_INDICATORS_ROUTE +
                                    "/" +
                                    item.id
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.social_economic_indicators_files.length > 0
                                ? item.social_economic_indicators_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{
                                                textAlign: "justify",
                                              }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab eventKey="Bugetul Raional" title="Bugetul Raional">
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {economy.budget.count > 0
                  ? economy.budget.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  href={
                                    SOCIAL_ECONOMIC_INDICATORS_ROUTE +
                                    "/" +
                                    item.id
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.district_budget_files.length > 0
                                ? item.district_budget_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{
                                                textAlign: "justify",
                                              }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
          <Tab
            eventKey="Planul de Marketing in Turism"
            title="Planul de Marketing in Turism"
          >
            <div className="d-flex flex-column">
              <Accordion className="pb-4">
                {economy.tourismMarketing.count > 0
                  ? economy.tourismMarketing.rows.map((item, idx) => (
                      <div
                        key={idx}
                        className="shadow-sm border-0 my-2 px-4 rounded"
                      >
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                            <div className="d-flex flex-xl-row flex column align-items-center">
                              {item.img ? (
                                <Image
                                  className="rounded"
                                  src={process.env.REACT_APP_API_URL + item.img}
                                  style={{ maxHeight: "30vh" }}
                                />
                              ) : null}
                              <div className="d-flex flex-column  ps-xl-4">
                                <a
                                  role="button"
                                  href={
                                    SOCIAL_ECONOMIC_INDICATORS_ROUTE +
                                    "/" +
                                    item.id
                                  }
                                  className="text-decoration-none text-primary"
                                >
                                  <Card.Title className="truncate">
                                    {item.title}
                                  </Card.Title>
                                </a>
                                {item.description ? (
                                  <div className="truncate">
                                    {parse(item.description)}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                              <CustomToggle
                                eventKey={idx}
                                onClick={() => {
                                  useAccordionButton({ idx });
                                }}
                              >
                                <i className="bi bi-pencil-square icon-2 pe-2" />
                                <span>Edit</span>
                              </CustomToggle>
                              <button
                                className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                onClick={() => removeItem(item)}
                              >
                                <i className="bi bi-x-circle icon-2 pe-2" />
                                <span>Remove</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey={idx}>
                          <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                            <div className="d-flex flex-column justify-content-around px-4 py-4">
                              <span className="text-primary  font-2 py-2 ps-1">
                                Denumire
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.title}
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Descriere
                              </span>
                              <Form.Control
                                aria-label="Title"
                                placeholder={item.description}
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Fotografie
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectFile}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                              />
                              <span className="text-primary  font-2 py-2 ps-1">
                                Documente
                              </span>
                              <Form.Control
                                type={"file"}
                                onChange={selectMultipleFiles}
                                aria-label="PageTitle"
                                aria-describedby="basic-addon"
                                multiple
                              />
                              {item.tourism_marketing_plan_files.length > 0
                                ? item.tourism_marketing_plan_files.map(
                                    (sub_item, sub_idx) => (
                                      <div
                                        key={sub_idx}
                                        className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                      >
                                        <div className="d-flex align-items-center">
                                          <i
                                            className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                          />
                                          <a
                                            href={
                                              process.env.REACT_APP_API_URL +
                                              sub_item.doc
                                            }
                                          >
                                            <span
                                              className="text-wrap"
                                              style={{
                                                textAlign: "justify",
                                              }}
                                            >
                                              {sub_item.doc.split(".")[0]}
                                            </span>
                                          </a>
                                        </div>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() =>
                                            removeItemFile(sub_item)
                                          }
                                        >
                                          <i className="bi bi-x-circle font-2"></i>
                                        </button>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                            <button
                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                              onClick={() => editItem(item)}
                            >
                              <i className="bi bi-check icon-2 pe-2" />
                              <span>Confirm</span>
                            </button>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    ))
                  : null}
              </Accordion>
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddEconomyModal
        show={show}
        onHide={() => setShow(false)}
        selectedTab={selectedTab}
      />
    </div>
  );
});

export default EconomyAdmin;
