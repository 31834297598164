import React, { useContext, useState } from "react";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { Collapse } from "react-bootstrap";

const LocalDecisionsActs = () => {
	const { administration } = useContext(Context);
	const [state, setState] = useState(false);
	return (
		<div className='d-flex flex-md-row flex-column justify-content-around px-4 border-bottom'>
			<div className='d-flex flex-column px-5 py-4 col-md-8'>
				<div className='block-title pb-4'>
					<span>Decizii/Acte Locale</span>
				</div>
				<div className='d-flex flex-column align-items-center'>
					{administration.draftDecisions.map((item, idx) => (
						<div
							key={idx}
							className='d-flex flex-column justify-content-between border-bottom py-3 col-12 text-justify'
							aria-controls={"collapse-item"}
							aria-expanded={item.isActive}>
							<div
								key={idx}
								className='d-flex flex-row align-items-center'>
								<i className='bi bi-file-text icon-5 pe-4 text-primary' />
								<div className='d-flex flex-column'>
									<span className='fw-bold font-1 text-primary'>{item.title}</span>
									<span
										className='text-wrap'
										style={{ textAlign: "justify" }}>
										{item.article}
									</span>
								</div>
								{item.isActive ? (
									<i
										onClick={() => {
											item.isActive = !item.isActive;
											setState(!state);
										}}
										className='bi bi-chevron-up icon-2 ps-4 text-primary'
										role='button'
									/>
								) : (
									<i
										onClick={() => {
											item.isActive = !item.isActive;
											setState(!state);
										}}
										className='bi bi-chevron-down icon-2 ps-4 text-primary'
										role='button'
									/>
								)}
							</div>
							<div className='d-flex justify-content-start align-items-center text-center py-2'>
								<span className='border rounded border-primary px-2 text-primary'>{item.date}</span>
							</div>
							<Collapse in={item.isActive}>
								<div id={"collapse-item"}>
									{item.files.map((sub_item, sub_idx) => (
										<a
											href='#'
											key={sub_idx}
											className='text-primary text-decoration-none'>
											<div className='d-flex align-items-center'>
												<i
													className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
												/>
												<span
													className='text-wrap'
													style={{ textAlign: "justify" }}>
													{sub_item.doc_name}
												</span>
											</div>
										</a>
									))}
								</div>
							</Collapse>
						</div>
					))}
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default LocalDecisionsActs;
