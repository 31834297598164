import { $authHost, $host } from "./index";

// export const createEx = async (ex) => {
// 	const { data } = await $authHost.post("api/ex", ex);
// 	return data;
// };

// export const fetchEX = async () => {
// 	const { data } = await $host.get("api/ex");
// 	return data;
// };

export const createCalendarEvents = async (calendarEvents) => {
	const { data } = await $authHost.post("api/calendar", calendarEvents);
	return data;
};

export const fetchCalendarEvents = async () => {
	const { data } = await $host.get("api/calendar");
	return data;
};

export const fetchOneCalendarEvents = async (id) => {
	const { data } = await $host.get("api/calendar/" + id);
	return data;
};

export const removeOneCalendarEvents = async (id) => {
	const { data } = await $host.post("api/calendar/" + id);
	return data;
};

export const updateOneCalendarEvents = async (processData, id) => {
	const { data } = await $host.post("api/calendar/u/" + id, processData);
	return data;
};
