import React, { useEffect, useState } from "react";
import Calendar from "../components/Calendar";
import { Spinner, Image } from "react-bootstrap";
import { fetchOneExtraordinaryCommissions } from "../http/resourcesServicesAPI";
import parse from "html-react-parser";
import ShareButton from "../components/UI/ShareButton";

const ExtraordinaryCommittees = () => {
	const [data, setData] = useState([null]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchOneExtraordinaryCommissions(1)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div className='d-flex flex-column col-xl-8 px-4'>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column align-items-center justify-content-center'>
							<Image
								src={process.env.REACT_APP_API_URL + data.img}
								className='rounded'
								style={{ minHeight: "40vh", maxHeight: "60vh", width: "auto" }}
							/>
							<span className='text-wrap py-2'>{parse(data.description)}</span>
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-5'>
							{data.author ? (
								<span className='border border-primary rounded text-primary my-sm-2 p-2 fw-bold'>Autor: {data.author}</span>
							) : null}
							{data.date ? <span className='border border-primary rounded text-primary p-2 fw-bold'>{data.date}</span> : null}
							<div className='d-flex flex-row align-items-center justify-content-end'>
								<i
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default ExtraordinaryCommittees;
