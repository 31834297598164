import { makeAutoObservable } from "mobx";

export default class RegulationsStore {
	constructor() {
		this._regulations = [
			// {
			//   id: 1,
			//   date: "18.05.2024 11:34",
			//   img: "",
			//   title: "Proiect nr. 1",
			//   active: false,
			//   date: "18.05.2024 11:34",
			//   files: [
			//     {
			//       id: 1,
			//       doc_name: "Test Doc 1",
			//       doc_type: "pdf",
			//     },
			//     {
			//       id: 2,
			//       doc_name: "Test Doc 2",
			//       doc_type: "docx",
			//     },
			//   ],
			// },
		];
		makeAutoObservable(this);
	}

	setRegulations(regulations) {
		this._regulations = regulations;
	}

	get regulations() {
		return this._regulations;
	}
}
