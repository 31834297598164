import { useState, useEffect, useRef } from "react";
import Calendar from "../../components/Calendar";
import { fetchOneLastNews } from "../../http/NewsAPI";
import { Spinner } from "react-bootstrap";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import ShareButton from "../../components/UI/ShareButton";

const LastNewsPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchOneLastNews(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div
						className='d-flex flex-column col-xl-8 px-xl-4'
						ref={printRef}>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-xl-row flex-column justify-content-center'>
							<span className='text-wrap'>{parse(data.article)}</span>
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100'>
							<div className='d-flex my-3'>
								<span className='border border-primary rounded text-primary p-2 fw-bold ms-4'>Autor: {data.author}</span>
								<span className='border border-primary rounded text-primary p-2 fw-bold ms-4'>{data.date}</span>
							</div>
							<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
								<i
									role='button'
									onClick={() => handlePrint()}
									className='bi bi-printer text-primary icon-3'
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								{/* <div
									className='d-flex rounded ms-3 px-4 bg-primary text-white align-items-center'
									style={{ width: 146 }}>
									<i className='bi bi-share icon-2' />
									<span className='ps-4 font-1 fw-bold'>Share</span>
								</div> */}
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default LastNewsPage;
