import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ShareButton = () => {
	const [isSharing, setIsSharing] = useState(false);
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const getShareUrl = (platform) => {
		const baseUrl = "https://www.facebook.com/sharer.php?u="; // Replace with appropriate base URLs for other platforms

		switch (platform) {
			case "facebook":
				return baseUrl + encodeURIComponent(window.location.href);
			case "twitter":
				return `https://twitter.com/intent/tweet?text=${encodeURIComponent(document.title)}&url=${encodeURIComponent(
					window.location.href
				)}`;
			case "telegram":
				return `https://t.me/share/url?url=${encodeURIComponent(document.title)}&url=${encodeURIComponent(window.location.href)}`;
			// Add more cases for other platforms
			default:
				return "";
		}
	};

	const handleShare = (platform) => {
		// Construct the share URL based on the platform
		const shareUrl = getShareUrl(platform);

		// Open the share URL in a new window
		window.open(shareUrl, "_blank");

		setIsSharing(true);
		setTimeout(() => setIsSharing(false), 2000); // Close the popup after 2 seconds
	};

	return (
		<div>
			<div
				role='button'
				className='d-flex rounded ms-3 px-4 bg-primary text-white align-items-center'
				onClick={handleShow}
				style={{ width: 146 }}>
				<i className='bi bi-share icon-2' />
				<span className='ps-4 font-1 fw-bold'>Share</span>
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop='static'
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title className='text-primary'>Share</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className='d-flex flex-column align-items-center justify-content-center'>
						<Button
							className='d-flex text-white my-1 justify-content-between'
							style={{ minWidth: "200px" }}
							onClick={() => handleShare("facebook")}>
							<i className='bi bi-facebook px-2' />
							<span className='text-center'>Share on Facebook</span>
						</Button>
						<Button
							className='d-flex text-white my-1 justify-content-between'
							style={{ minWidth: "200px" }}
							onClick={() => handleShare("twitter")}>
							<i className='bi bi-twitter-x px-2' />
							<span className='text-center'>Share on Twitter</span>
						</Button>
						<Button
							className='d-flex text-white my-1 justify-content-between'
							style={{ minWidth: "200px" }}
							onClick={() => handleShare("telegram")}>
							<i className='bi bi-telegram px-2' />
							<span className='text-center'>Share on Telegram</span>
						</Button>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={handleClose}>
						Închide
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ShareButton;
