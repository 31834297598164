import { makeAutoObservable } from "mobx";

export default class CitizensHearingProgramStore {
	constructor() {
		this._program = [
			// id: 1,
			// img: "",
			// persons: [
			//   {
			//     profile_photo:
			//       "https://www.zdg.md/wp-content/uploads/2021/03/nicolai-melnic.jpg",
			//     location: "Cabinetul nr. 12",
			//     post: "Președintele Raionului",
			//     name: "Nicolai Melnic",
			//     phone: "0(246) 2-26-50",
			//     email: "example@gmail.com",
			//     schedule_days: "Luni",
			//     schedule_time: "10:00 - 12:00",
			//   },
			//   {
			//     profile_photo: "",
			//     location: "Cabinetul nr. 14",
			//     post: "Vicepreşedinte al raionului",
			//     name: "Oleg Sanduleac",
			//     phone: "0(246) 2-26-50",
			//     email: "example@gmail.com",
			//     schedule_days: "Luni",
			//     schedule_time: "10:00 - 14:00",
			//   },
			//   {
			//     profile_photo: "",
			//     location: "Cabinetul nr. 16",
			//     post: "Vicepreşedinte al raionului",
			//     name: "Sorocan Vitalie",
			//     phone: "0(246) 2-26-50",
			//     email: "example@gmail.com",
			//     schedule_days: "Luni",
			//     schedule_time: "10:00 - 14:00",
			//   },
			// ],
		];
		makeAutoObservable(this);
	}

	setProgram(program) {
		this._program = program;
	}

	get program() {
		return this._program;
	}
}
