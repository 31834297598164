import React, { useContext, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Calendar from "./Calendar";
import { Link, useNavigate } from "react-router-dom";
import { EVENT_PAGE_ROUTE } from "../utils/consts";
import ShareButton from "./UI/ShareButton";

const EventList = observer(() => {
	const { calendarEvents, contactInformation } = useContext(Context);
	const navigate = useNavigate();

	const printRef = useRef(null);

	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};
	return (
		<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2 border-bottom'>
			<div className='px-5 col-xl-9 py-4'>
				<div className='block-title'>
					<span>Events</span>
				</div>
				<div
					className='d-flex flex-row flex-wrap justify-content-around'
					ref={printRef}>
					{calendarEvents.events.count > 0
						? calendarEvents.events.rows.map((item, idx) => (
								<Card
									key={idx}
									style={{ width: "22rem" }}
									className='my-3 shadow border-0'>
									<Card.Title className='py-2 d-flex text-center text-white align-items-center justify-content-center bg-primary u-corner-radius'>
										<span className='px-2 truncate'>{item.title}</span>
									</Card.Title>
									<Card.Body className='px-3 d-flex flex-column'>
										<div className='d-flex flex-column'>
											<div className='d-flex flex-row align-items-center justify-content-between pb-3'>
												<i className='bi bi-geo-alt text-primary icon-2' />
												<span className='rounded text-primary px-2 py-1'>{item.location}</span>
											</div>
											<div className='d-flex flex-row align-items-center justify-content-between'>
												<i className='bi bi-person-vcard text-primary icon-2' />
												<span className='text-primary fw-bold px-2 py-1'>{item.organizer}</span>
											</div>
											<div className='d-flex flex-row align-items-center justify-content-between pt-3'>
												<i className='bi bi-clock text-primary icon-2' />
												<span className='border border-primary rounded text-primary px-2 py-1'>{item.date}</span>
												<span className='border border-primary rounded text-primary px-2 py-1'>{item.time}</span>
											</div>
										</div>
										<Button
											onClick={() => navigate(EVENT_PAGE_ROUTE + "/" + item.id)}
											className='mt-3'>
											Citește mai mult...
										</Button>
									</Card.Body>
								</Card>
						  ))
						: null}
				</div>

				<div className='map d-flex flex-column py-4'>
					<div className='block-title'>
						<span>Urmăriți-ne pe</span>
					</div>
					<div className='d-flex flex-xl-row flex-column justify-content-between'>
						<div className='d-flex flex-xl-row flex-column justify-content-around py-3'>
							<Link
								to={contactInformation.contactInformation.youtube_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-youtube pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>YOUTUBE</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.facebook_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-facebook pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>FACEBOOK</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.telegram_link}
								className='d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none '>
								<i className='bi bi-telegram pe-3 icon-3 text-primary' />
								<span className='text-primary font-2'>TELEGRAM</span>
							</Link>
						</div>
						<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</div>
	);
});

export default EventList;
