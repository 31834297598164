import { makeAutoObservable } from "mobx";

export default class CouncillorsStore {
	constructor() {
		this._councillors = [
			// {
			//   party:
			//     "Partidul Politic „Partidul Socialiştilor din Republica Moldova”(PSRM)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "BARANOVSCHI IURI",
			//       birthdate: "1967",
			//     },
			//     {
			//       id: 2,
			//       full_name: "BELINSCAIA TATIANA",
			//       birthdate: "1967",
			//     },
			//     {
			//       id: 3,
			//       full_name: "BUCIULEA MIHAIL",
			//       birthdate: "1978",
			//     },
			//     {
			//       id: 4,
			//       full_name: "COJULENCO MARIA",
			//       birthdate: "1973",
			//     },
			//     {
			//       id: 5,
			//       full_name: "GAIFER TATIANA",
			//       birthdate: "1966",
			//     },
			//     {
			//       id: 6,
			//       full_name: "GUȚU ALA",
			//       birthdate: "1985",
			//     },
			//     {
			//       id: 7,
			//       full_name: "IAŢCO VICTOR",
			//       birthdate: "1951",
			//     },
			//     {
			//       id: 8,
			//       full_name: "IVANIŢA RITA",
			//       birthdate: "1972",
			//     },
			//     {
			//       id: 9,
			//       full_name: "MELNIC ION",
			//       birthdate: "1994",
			//     },
			//     {
			//       id: 10,
			//       full_name: "RUSSU VALENTINA",
			//       birthdate: "1965",
			//     },
			//     {
			//       id: 11,
			//       full_name: "SANDULEAC INNA",
			//       birthdate: "1989",
			//     },
			//     {
			//       id: 12,
			//       full_name: "TURCINSCAIA NADEJDA",
			//       birthdate: "1953",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Politic „Partidul Acțiune și Solidaritate”(PAS)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "CIUBOTARU VIOREL",
			//       birthdate: "1970",
			//     },
			//     {
			//       id: 2,
			//       full_name: "DOCIU EUGENIA",
			//       birthdate: "1963",
			//     },
			//     {
			//       id: 3,
			//       full_name: "DOLIŞCINSCHI SERGIU",
			//       birthdate: "1962",
			//     },
			//     {
			//       id: 4,
			//       full_name: "GUŢU ALEXANDRU",
			//       birthdate: "1986",
			//     },
			//     {
			//       id: 5,
			//       full_name: "URSACHI MIRABELA",
			//       birthdate: "1987",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Politic Liga Oraşelor şi Comunelor(PPLOC)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "COJOCARI ŞTEFAN",
			//       birthdate: "1995",
			//     },
			//     {
			//       id: 2,
			//       full_name: "MAGU LUDMILA",
			//       birthdate: "1955",
			//     },
			//     {
			//       id: 3,
			//       full_name: "MOSORIC GALINA",
			//       birthdate: "1952",
			//     },
			//     {
			//       id: 4,
			//       full_name: "RUSANOVSCHI VICTOR",
			//       birthdate: "1971",
			//     },
			//     {
			//       id: 5,
			//       full_name: "TARTAN ION",
			//       birthdate: "1957",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Politic Partidul Social Democrat European(PPPSDE)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "BELITEI STELA",
			//       birthdate: "1968",
			//     },
			//     {
			//       id: 2,
			//       full_name: "GARAS IURII",
			//       birthdate: "1957",
			//     },
			//     {
			//       id: 3,
			//       full_name: "MUȘCINSCHI INA",
			//       birthdate: "1983",
			//     },
			//     {
			//       id: 4,
			//       full_name: "ODOBESCU GHENADIE",
			//       birthdate: "1976",
			//     },
			//     {
			//       id: 5,
			//       full_name: "SÎRBU OLEG",
			//       birthdate: "1967",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Liberal Democrat din Moldova(PLDM)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "NISTOR VIOLETA",
			//       birthdate: "1967",
			//     },
			//     {
			//       id: 2,
			//       full_name: "VEREMCIUC MARIN",
			//       birthdate: "1975",
			//     },
			//   ],
			// },
			// {
			//   party: "Candidat independent",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "TUREAC ALEXANDRU",
			//       birthdate: "1986",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Comuniştilor din Republica Moldova(PCRM)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "ZIMIN LARISA",
			//       birthdate: "1954",
			//     },
			//   ],
			// },
			// {
			//   party:
			//     "Partidul Politic „Partidul Dezvoltării şi Consolidării Moldovei”(PDCM)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "ROTARI SERGHEI",
			//       birthdate: "1956",
			//     },
			//   ],
			// },
			// {
			//   party: "Partidul Politic Partidul „RENAȘTERE”(PR)",
			//   members: [
			//     {
			//       id: 1,
			//       full_name: "GRONIC IUNESA",
			//       birthdate: "1992",
			//     },
			//   ],
			// },
		];
		makeAutoObservable(this);
	}

	setCouncillors(councillors) {
		this._councillors = councillors;
	}

	get councillors() {
		return this._councillors;
	}
}
