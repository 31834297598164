import { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneMayorOnline, fetchOneOnlineMeetings } from "../../http/NewsAPI";
import parse from "html-react-parser";
import ShareButton from "../../components/UI/ShareButton";

const HeadOnlinePage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOneMayorOnline(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div className='d-flex flex-column col-xl-8 px-xl-4'>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center pb-4'>
							{data.link ? (
								<iframe
									title='stream'
									src={data.link}
									className='w-100 rounded shadow'
									style={{ minHeight: "70vh" }}
								/>
							) : null}
							<div className='text-justify font-2 pb-2'>{parse(data.article)}</div>
						</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 py-3 '>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>Autor: {data.author}</span>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>{data.date}</span>
							<div className='d-flex flex-row align-items-center justify-content-end my-1 ps-xl-1'>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default HeadOnlinePage;
