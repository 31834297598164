import React, { useContext, useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Context } from "..";
import { fetchInitiatingPublicPrivatePartnerships } from "../http/resourcesServicesAPI";
import { Spinner } from "react-bootstrap";
import parse from "html-react-parser";
import ShareButton from "../components/UI/ShareButton";
import { Link } from "react-router-dom";

const InitiatingPublicPrivatePartnerships = () => {
	const { resourcesServices } = useContext(Context);

	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	// useEffect(() => {
	// 	fetchInitiatingPublicPrivatePartnerships()
	// 		.then((data) => {
	// 			resourcesServices.setInitiatingPublicPrivatePartnerships(data);
	// 		})
	// 		.finally(() => setLoading(false));
	// }, []);
	// if (loading) {
	// 	return (
	// 		<div className='d-flex align-items-center justify-content-center py-5'>
	// 			<Spinner animation={"grow"} />
	// 		</div>
	// 	);
	// }

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4'>
				<div
					className='d-flex flex-column col-xl-8 col-12 px-xl-4 px-2'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>
							Redirecționarea 2% Din Impozitul Pe Venit Către Asociația Veteranilor și Rezerviștilor din Republica Moldova
						</span>
					</div>
					<div className='d-flex flex-column justify-content-center'>
						{/* <span className='text-justify font-2 py-2 px-2'>
							{resourcesServices.initiatingPublicPrivatePartnerships.count > 0
								? parse(resourcesServices.initiatingPublicPrivatePartnerships.rows[0].description)
								: null}
						</span>
						{resourcesServices.initiatingPublicPrivatePartnerships.count > 0
							? resourcesServices.initiatingPublicPrivatePartnerships.rows.map((item, idx) =>
									item.initiating_public_private_partnerships_files.map((sub_item, sub_idx) => (
										<a
											href={process.env.REACT_APP_API_URL + sub_item.doc}
											target='_blank'
											download
											key={sub_idx}
											className='text-primary text-decoration-none py-2'>
											<div className='d-flex align-items-center'>
												<i
													className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
												/>
												<span
													className='text-wrap'
													style={{ textAlign: "justify" }}>
													{sub_item.doc.split(".")[0]}
												</span>
											</div>
										</a>
									))
							  )
							: null}
						<Link
							to={process.env.REACT_APP_API_URL + resourcesServices.initiatingPublicPrivatePartnerships.rows[0].img}
							target='_blank'
							download
							className='d-flex justify-content-center align-items-center py-2'>
							<Image
								className='rounded'
								style={{
									maxHeight: "70vh",
									width: "auto",
									aspectRatio: 1,
									objectFit: "contain",
								}}
								src={
									resourcesServices.initiatingPublicPrivatePartnerships.count > 0
										? process.env.REACT_APP_API_URL + resourcesServices.initiatingPublicPrivatePartnerships.rows[0].img
										: null
								}
							/>
						</Link> */}
						<div>
							<p>
								Poți contribui la o cauză nobilă și să faci o diferență în viața veteranilor și rezerviștilor din Republica
								Moldova? Prin simpla acțiune de a redirecționa 2% din impozitul tău pe venit, poți susține activitățile
								Asociației Veteranilor și Rezerviștilor, contribuind astfel la îmbunătățirea condițiilor de viață și la
								oferirea de asistență celor care au servit țara.
							</p>
							<p>
								<strong>De ce este important să susții această cauză?</strong>
							</p>
							<ul>
								<li>
									<strong>Recunoaștere și apreciere:</strong> Prin această gest, îi arăți veteranilor și rezerviștilor că
									eforturile lor sunt apreciate și că societatea îi sprijină.
								</li>
								<li>
									<strong>Îmbunătățirea calității vieții:</strong> Fondurile obținute prin redirecționarea a 2% pot fi
									utilizate pentru a finanța proiecte care vizează îmbunătățirea condițiilor de viață ale veteranilor și
									rezerviștilor, precum și pentru a oferi asistență medicală, psihologică și socială.
								</li>
								<li>
									<strong>Întărirea comunității:</strong> Prin susținerea acestei asociații, contribui la consolidarea
									comunității veteranilor și la crearea unui mediu de susținere reciprocă.
								</li>
							</ul>
							<p></p>
							<p>
								<strong>Cum poți redirecționa 2% din impozitul tău?</strong>
							</p>
							<p>
								Este foarte simplu! Trebuie doar să completezi și să depui Declarația cu privire la impozitul pe venit
								(Formularul CET18) în termenul stabilit de legislația fiscală. În această declarație, vei găsi o secțiune
								specială unde vei putea indica Asociația Veteranilor și Rezerviștilor din Republica Moldova ca beneficiar al
								celor 2% din impozitul tău.
							</p>
							<p>
								<strong>De ce să alegi Asociația Veteranilor și Rezerviștilor?</strong>
							</p>
							<p>
								Asociația Veteranilor și Rezerviștilor din Republica Moldova este o organizație care își propune să apere
								drepturile și interesele veteranilor și rezerviștilor, să le ofere asistență socială și medicală, precum și
								să promoveze spiritul patriotic și valorile democratice.
							</p>
							<p>
								<strong>Alătură-te celor care fac o diferență!</strong>
							</p>
							<p>
								Redirecționând 2% din impozitul tău, devii parte a unei mișcări care își propune să îmbunătățească viața
								celor care au servit țara. Împreună, putem construi o societate mai bună și mai solidară!
							</p>
							<p>
								<strong>Pentru mai multe informații, te rugăm să contactezi:</strong>
							</p>
							<ul>
								<li>
									<strong>Telefon: </strong>+(373) 781 71 717
								</li>
								<li>
									<strong>Email: </strong>veteran.moldova@gmail.com
								</li>
								<li>
									<strong>Website: </strong>
									<a
										href='https://2procente.info/beneficiari/asociatia-veteranilor-si-rezervistilor-din-republica-moldova/'
										rel='noopener noreferrer'
										target='_blank'>
										https://2procente.info/beneficiari/asociatia-veteranilor-si-rezervistilor-din-republica-moldova/
									</a>
								</li>
							</ul>
							<p></p>
							<p>
								<strong>Nu uita, fiecare contribuție contează!</strong>
							</p>
							<p>
								<em>
									Acest articol are scopul de a informa și de a încuraja oamenii să se implice în activități de
									voluntariat și să susțină cauze nobile.
								</em>
							</p>
							<p></p>
							<p>
								<strong>Cod fiscal:</strong> 1013620001257
							</p>
							<p>
								<strong>Forma juridică de organizare: </strong> Asociație Obștească
							</p>
							<p>
								<strong>Domeniul principal de activitate: </strong> Democrație și Drepturile Omului
							</p>
							<p>
								<strong>Domeniul principal de activitate: </strong> Educație
							</p>
							<p>
								<strong>Date de contact: </strong>
								<a
									href='https://www.facebook.com/Asociatia-Veteranilor-și-Rezerviștilor-din-Republica-Moldova-505457976226927/about/'
									rel='noopener noreferrer'
									target='_blank'
									role='button'>
									<strong>Facebook</strong>
								</a>
							</p>
							<p>
								<strong>Exemplu de completare a formularului </strong>
								<a
									href='https://api.veteranii.md/35bf2001-55a8-4f60-95fe-27a44ac5be06.jpg'
									rel='noopener noreferrer'
									target='_blank'
									role='button'>
									<strong>Formular</strong>
								</a>
							</p>
						</div>
					</div>

					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<div className='d-flex flex-row align-items-center justify-content-end ps-1'>
							<i
								role='button'
								onClick={() => handlePrint()}
								className='bi bi-printer text-primary icon-3'
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<ShareButton />
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default InitiatingPublicPrivatePartnerships;
