import { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { Carousel, Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOnePhotoGallery } from "../../http/NewsAPI";
import parse from "html-react-parser";
import ShareButton from "../../components/UI/ShareButton";

const PhotoGalleryPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOnePhotoGallery(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				{data ? (
					<div className='d-flex flex-column col-xl-8 px-xl-4'>
						<div className='py-4 block-title text-center'>
							<span className='text-primary'>{data.title}</span>
						</div>
						<div className='d-flex flex-column justify-content-center pb-4'>
							<span className='text-justify font-2 pb-2'>{data.description}</span>
							<Carousel fade>
								{data.photo_gallery_files.length > 0
									? data.photo_gallery_files.map((sub_item, sub_idx) => (
											<Carousel.Item
												key={sub_idx}
												className='h-100'>
												<div
													className='rounded'
													style={{
														background: `url(${
															process.env.REACT_APP_API_URL + sub_item.img
														})  center center/cover no-repeat`,
														minHeight: "75vh",
														backgroundSize: "cover !important",
														height: "100%",
													}}
												/>
												<Carousel.Caption>
													<h3 className='text-start'>{sub_item.img_description}</h3>
												</Carousel.Caption>
											</Carousel.Item>

											// <a
											// 	href={process.env.REACT_APP_API_URL + sub_item.doc}
											// 	target="_blank"
											// 	download
											// 	key={sub_idx}
											// 	className="text-primary text-decoration-none py-2"
											// >
											// 	<div className="d-flex align-items-center">
											// 		<i
											// 			className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
											// 		/>
											// 		<span className="text-wrap font-2" style={{ textAlign: "justify" }}>
											// 			{sub_item.doc.split(".")[0]}
											// 		</span>
											// 	</div>
											// </a>
									  ))
									: null}
							</Carousel>
						</div>
						<div className='text-justify font-2 pb-2'>{parse(data.article)}</div>
						<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 py-3 '>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>Autor: {data.author}</span>
							<span className='border border-primary rounded text-primary my-1 p-2 fw-bold'>{data.date}</span>
							<div className='d-flex flex-row align-items-center justify-content-end my-1 ps-xl-1'>
								<ShareButton />
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default PhotoGalleryPage;
