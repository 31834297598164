import { makeAutoObservable } from "mobx";

export default class CalendarStore {
	constructor() {
		this._events = [
			// {
			//   id: 0,
			//   title: "Sedinta operativa a comissiei Situatiilor Extraordinare",
			//   date: "Thu Aug 08 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "Consiliul raional Edinet consiliul raional Edinet Intrunirea reprezentantilor ONG cu Administratia raionala Intrunirea reprezentantilor ONG cu Administratia Raionala",
			//   location: "Edinet",
			//   time: "08:45",
			// },
			// {
			//   id: 1,
			//   title: "Ziua Porților Deschise la Guvern I",
			//   date: "Wed Aug 14 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "Un eveniment în care cetățenii pot vizita instituțiile guvernamentale, pot discuta cu funcționarii și pot afla mai multe despre activitatea acestora",
			//   location: "Edinet",
			//   time: "09:00",
			// },
			// {
			//   id: 2,
			//   title: "Forumul Cetățenilor Activi",
			//   date: "Sun Aug 11 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "O platformă de dezbatere pentru inițiative cetățenești și proiecte comunitare.",
			//   location: "Edinet",
			//   time: "08:45",
			// },
			// {
			//   id: 3,
			//   title: "Intrunirea reprezentantilor ONG cu Administratia raionala",
			//   date: "Mon Aug 12 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "Intrunirea reprezentantilor ONG cu Administratia Consiliului Raional Edineț",
			//   location: "Edinet",
			//   time: "10:30",
			// },
			// {
			//   id: 4,
			//   title: "Ziua Porților Deschise la Guvern II",
			//   date: "Wed Aug 14 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "Un eveniment în care cetățenii pot vizita instituțiile guvernamentale, pot discuta cu funcționarii și pot afla mai multe despre activitatea acestora",
			//   location: "Edinet",
			//   time: "14:00",
			// },
			// {
			//   id: 4,
			//   title: "Ziua Porților Deschise la Guvern II",
			//   date: "Sat Aug 17 2024",
			//   organizer: "Consiliul raional Edinet",
			//   img: "https://picsum.photos/600/600",
			//   description:
			//     "Un eveniment în care cetățenii pot vizita instituțiile guvernamentale, pot discuta cu funcționarii și pot afla mai multe despre activitatea acestora",
			//   location: "Edinet",
			//   time: "14:00",
			// },
			// {
			//   id: 1,
			//   title: "Meeting",
			//   date: "Fri Jul 26 2024",
			//   organizer: "Organizatia de cultura si sport",
			//   description:
			//     "Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
			//   location: "Balti",
			//   time: "08:45",
			// },
			// {
			//   id: 2,
			//   title: "Football",
			//   date: "Sat Jul 27 2024",
			//   organizer: "Organizatia de cultura si sport",
			//   description:
			//     "Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
			//   location: "Balti",
			//   time: "10:45",
			// },
			// {
			//   id: 4,
			//   title: "Football Round 2",
			//   date: "Sat Jul 27 2024",
			//   organizer: "Organizatia de cultura si sport",
			//   description:
			//     "Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
			//   location: "Balti",
			//   time: "12:45",
			// },
			// {
			//   id: 3,
			//   title: "Fight",
			//   date: "Sun Jul 28 2024",
			//   organizer: "Organizatia de cultura si sport",
			//   description:
			//     "Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
			//   location: "Balti",
			//   time: "12:35",
			// },
		];
		makeAutoObservable(this);
	}

	setEvent(events) {
		this._events = events;
	}

	get events() {
		return this._events;
	}
}

// this._events = [
// 	{
// 		id: 1,
// 		title: "Meeting",
// 		date: "Fri Jul 26 2024",
// 		organizer: "Organizatia de cultura si sport",
// 		description:
// 			"Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
// 		location: "Balti",
// 		time: "08:45",
// 	},
// 	{
// 		id: 2,
// 		title: "Football",
// 		date: "Sat Jul 27 2024",
// 		organizer: "Organizatia de cultura si sport",
// 		description:
// 			"Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
// 		location: "Balti",
// 		time: "10:45",
// 	},
// 	{
// 		id: 4,
// 		title: "Football Round 2",
// 		date: "Sat Jul 27 2024",
// 		organizer: "Organizatia de cultura si sport",
// 		description:
// 			"Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
// 		location: "Balti",
// 		time: "12:45",
// 	},
// 	{
// 		id: 3,
// 		title: "Fight",
// 		date: "Sun Jul 28 2024",
// 		organizer: "Organizatia de cultura si sport",
// 		description:
// 			"Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport Organizatia de cultura si sport",
// 		location: "Balti",
// 		time: "12:35",
// 	},
// ];
