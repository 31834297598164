import { makeAutoObservable } from "mobx";

export default class LastNewsStore {
	constructor() {
		this._lastNews = [
			// {
			// 	id: 1,
			// 	img: "https://picsum.photos/400/250",
			// 	title: "Moldovan Parliament Approves New Anti-Corruption Law",
			// 	article:
			// 		"The Moldovan parliament has approved a new anti-corruption law aimed at strengthening the fight against corruption in the country. The law introduces stricter penalties for corruption offenses, expands the powers of anti-corruption agencies, and enhances whistleblower protections. Civil society groups have welcomed the law, but some have expressed concerns about its implementation.",
			// },
			// {
			// 	id: 2,
			// 	img: "https://picsum.photos/400/250",
			// 	title: "Moldovan Scientists Make Breakthrough in Cancer Research",
			// 	article:
			// 		"Moldovan scientists have made a breakthrough in cancer research, developing a new treatment that has shown promising results in clinical trials. The treatment uses a combination of immunotherapy and targeted therapy to attack cancer cells more effectively. This discovery could have a significant impact on the treatment of cancer patients worldwide.",
			// },
			// {
			// 	id: 3,
			// 	img: "https://picsum.photos/400/250",
			// 	title: " Moldovan Startup Attracts International Investment",
			// 	article:
			// 		"A Moldovan startup has attracted significant international investment for its innovative technology platform. The company's platform helps businesses manage their supply chains and logistics operations more efficiently. This investment will allow the company to expand its operations and reach new markets.",
			// },
			// {
			// 	id: 4,
			// 	img: "https://picsum.photos/400/250",
			// 	title: "Moldovan Women's Football Team Qualifies for European Championship",
			// 	article:
			// 		" The Moldovan women's national football team has qualified for the European Championship for the first time in its history. This achievement is a testament to the growing popularity of women's football in Moldova and the dedication of the national team players. The team will face off against some of the best teams in Europe in the championship tournament.",
			// },
			// {
			// 	id: 5,
			// 	img: "https://picsum.photos/400/250",
			// 	title: "Moldovan Environmental Activists Campaign for Sustainable Development",
			// 	article:
			// 		"Moldovan environmental activists are campaigning for sustainable development in the country. They are calling on the government to adopt policies that promote renewable energy, reduce pollution, and protect biodiversity. The activists are also working to raise public awareness about environmental issues and encourage individuals to adopt eco-friendly practices.",
			// }
		];
		makeAutoObservable(this);
	}

	setLastNews(lastNews) {
		this._lastNews = lastNews;
	}

	get lastNews() {
		return this._lastNews;
	}
}
