import { useEffect, useState, useContext } from "react";
import { Form, Card, Spinner, useAccordionButton, Accordion } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchReferences, removeOneReferences, updateOneReferences } from "../../http/referencesAPI";
import AddReferenceModal from "./Modals/AddReferenceModal";
import { Link } from "react-router-dom";

const ReferencesAdmin = observer(() => {
	const { references } = useContext(Context);
	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const editReference = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("link", link);
		updateOneReferences(formData, id).then(() => window.location.reload());
	};

	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchReferences()
			.then((data) => {
				references.setReferences(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}

	const removeRef = (item) => {
		removeOneReferences(item.id).then(() => window.location.reload());
	};

	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'>
				<span>Referițe</span>
				<button
					className='btn btn-primary r-u-corner-radius'
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}>
					<i className='bi bi-plus-circle icon-2' />
				</button>
			</div>
			<Accordion className='d-flex flex-column px-3'>
				{references.references
					? references.references.map((item, idx) => (
							<div
								key={idx}
								className='shadow-sm border-0 my-2 px-4'>
								<div className='d-flex flex-column'>
									<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
										<div>
											<Link
												to={item.link}
												className='text-decoration-none text-primary'>
												<Card.Title className='truncate'>{item.title}</Card.Title>
											</Link>
											<Card.Text
												style={{
													textDecoration: "underline",
													color: "#0000EE",
												}}>
												{item.link}
											</Card.Text>
										</div>
										<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
											<CustomToggle
												eventKey={idx}
												onClick={() => {
													useAccordionButton({ idx });
												}}>
												<i className='bi bi-pencil-square icon-2 pe-2' />
												<span>Edit</span>
											</CustomToggle>
											<button
												className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
												onClick={() => removeRef(item)}>
												<i className='bi bi-x-circle icon-2 pe-2' />
												<span>Remove</span>
											</button>
										</div>
									</div>
								</div>
								<Accordion.Collapse eventKey={idx}>
									<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
										<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
										<Form.Control
											aria-label='Title'
											placeholder={item.title}
											value={title}
											onChange={(e) => {
												setTitle(e.target.value);
											}}
										/>
										<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
										<Form.Control
											aria-label='Link'
											placeholder={item.link}
											value={link}
											onChange={(e) => {
												setLink(e.target.value);
											}}
										/>
										<button
											className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
											onClick={() => editReference(item)}>
											<i className='bi bi-check icon-2 pe-2' />
											<span>Confirm</span>
										</button>
									</div>
								</Accordion.Collapse>
							</div>
					  ))
					: null}
			</Accordion>
			<AddReferenceModal
				show={show}
				onHide={() => setShow(false)}
			/>
		</div>
	);
});

export default ReferencesAdmin;
