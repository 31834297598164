import { useEffect, useState, useContext } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import {
    fetchCitizensHearingProgram,
    removeOneCitizensHearingProgram,
    updateOneCitizensHearingProgram,
} from "../../http/activitiesAPI";
import { useNavigate } from "react-router-dom";
import { MAIN_BANNER_INFO } from "../../utils/consts";
import parse from "html-react-parser";
import AddCitizensHearingProgramModal from "./Modals/AddCitizensHearingProgramModal";

const CitizensHearingProgramAdmin = observer(() => {
    const { activities } = useContext(Context);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [role, setRole] = useState("");
    const [full_name, setFullName] = useState("");
    const [location, setLocation] = useState("");
    const [phone, setPhone] = useState("");
    //   const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");
    const [img, setImg] = useState([null]);
    const [days, setDays] = useState("");
    const [time, setTime] = useState("");

    const navigate = useNavigate();

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const editMainBanner = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append("role", role);
        formData.append("full_name", full_name);
        formData.append("location", location);
        formData.append("phone", phone);
        // formData.append("fax", fax);
        formData.append("email", email);
        formData.append("img", img);
        formData.append("days", days);
        formData.append("time", time);
        updateOneCitizensHearingProgram(formData, id).then(() => window.location.reload());
    };

    const removeMainBanner = (item) => {
        removeOneCitizensHearingProgram(item.id).then(() => window.location.reload());
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    useEffect(() => {
        fetchCitizensHearingProgram()
            .then((data) => {
                activities.setCitizensHearingProgram(data);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Programul Audienței Cetățenilor</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-3">
                <Accordion className="d-flex flex-column px-3 ">
                    {activities.citizensHearingProgram.count > 0
                        ? activities.citizensHearingProgram.rows.map((item, idx) => (
                              <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                  <div className="d-flex flex-column">
                                      <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                          <div className="d-flex flex-xl-row flex-column justify-content-center">
                                              <Image
                                                  style={{
                                                      maxHeight: 200,
                                                      height: "auto",
                                                      objectFit: "contain",
                                                  }}
                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                  className="pe-xl-5 rounded"
                                              />
                                              <div className="d-flex flex-column justify-content-center">
                                                  {/* <a
                                                      role="button"
                                                      onClick={() => navigate(MAIN_BANNER_INFO + "/" + item.id)}
                                                      className="text-decoration-none text-primary"
                                                  ></a> */}
                                                  <Card.Title className="text-primary truncate text-center my-2">
                                                      {item.role}
                                                  </Card.Title>
                                                  {item.full_name ? (
                                                      <div className="truncate text-center my-2 font-2">
                                                          {item.full_name}
                                                      </div>
                                                  ) : null}
                                                  <div className="d-flex flex-row flex-wrap justify-content-center">
                                                      {item.days ? (
                                                          <div className="truncate px-3 py-1 border border-primary rounded mx-2 my-1">
                                                              {item.days}
                                                          </div>
                                                      ) : null}
                                                      {item.time ? (
                                                          <div className="truncate px-3 py-1 border border-primary rounded mx-2 my-1">
                                                              {item.time}
                                                          </div>
                                                      ) : null}
                                                      {item.phone ? (
                                                          <div className="truncate px-3 py-1 border border-primary rounded mx-2 my-1">
                                                              {item.phone}
                                                          </div>
                                                      ) : null}
                                                      {item.location ? (
                                                          <div className="truncate px-3 py-1 border border-primary rounded mx-2 my-1">
                                                              {item.location}
                                                          </div>
                                                      ) : null}
                                                      {item.email ? (
                                                          <div className="truncate px-3 py-1 border border-primary rounded mx-2 my-1">
                                                              {item.email}
                                                          </div>
                                                      ) : null}
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                              <CustomToggle
                                                  eventKey={idx}
                                                  onClick={() => {
                                                      useAccordionButton({ idx });
                                                  }}
                                              >
                                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                                  <span>Edit</span>
                                              </CustomToggle>
                                              <button
                                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                  onClick={() => removeMainBanner(item)}
                                              >
                                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                                  <span>Remove</span>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                                  <Accordion.Collapse eventKey={idx}>
                                      <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                          <span className="text-primary  font-2 py-2 ps-1">Poziția</span>
                                          <Form.Control
                                              aria-label="Title"
                                              value={role}
                                              placeholder={item.role}
                                              onChange={(e) => {
                                                  setRole(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Nume Complet</span>
                                          <Form.Control
                                              aria-label="Full Name"
                                              value={full_name}
                                              placeholder={item.full_name}
                                              onChange={(e) => {
                                                  setFullName(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                                          <Form.Control
                                              type={"file"}
                                              onChange={selectFile}
                                              aria-label="PageTitle"
                                              aria-describedby="basic-addon"
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Locație</span>
                                          <Form.Control
                                              aria-label="Location"
                                              value={location}
                                              placeholder={item.location}
                                              onChange={(e) => {
                                                  setLocation(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Telefon</span>
                                          <Form.Control
                                              aria-label="Phone Number"
                                              value={phone}
                                              placeholder={item.phone}
                                              onChange={(e) => {
                                                  setPhone(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Email</span>
                                          <Form.Control
                                              type="email" // Specify email type for validation
                                              aria-label="Email Address"
                                              value={email}
                                              placeholder={item.email}
                                              onChange={(e) => {
                                                  setEmail(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Zile de recepție</span>
                                          <Form.Control
                                              aria-label="Days"
                                              value={days}
                                              placeholder={item.days}
                                              onChange={(e) => {
                                                  setDays(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Ore de recepție</span>
                                          <Form.Control
                                              aria-label="Time"
                                              value={time}
                                              placeholder={item.time}
                                              onChange={(e) => {
                                                  setTime(e.target.value);
                                              }}
                                          />
                                          {/* <span className="text-primary  font-2 py-2 ps-1">Fax (Optional)</span>
                      <Form.Control
                        aria-label="Fax Number"
                        value={fax}
                        onChange={(e) => {
                          setFax(e.target.value);
                        }}
                      /> */}
                                          <button
                                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                              onClick={() => editMainBanner(item)}
                                          >
                                              <i className="bi bi-check icon-2 pe-2" />
                                              <span>Confirm</span>
                                          </button>
                                      </div>
                                  </Accordion.Collapse>
                              </div>
                          ))
                        : null}
                </Accordion>
            </div>
            <AddCitizensHearingProgramModal show={show} onHide={() => setShow(false)} />
        </div>
    );
});

export default CitizensHearingProgramAdmin;
