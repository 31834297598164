import { $authHost, $host } from "./index";

// export const createEx = async (ex) => {
// 	const { data } = await $authHost.post("api/ex", ex);
// 	return data;
// };

// export const fetchEX = async () => {
// 	const { data } = await $host.get("api/ex");
// 	return data;
// };

export const createMainBanner = async (mainBanner) => {
    const { data } = await $authHost.post("api/main_banner", mainBanner);
    return data;
};

export const fetchMainBanner = async () => {
    const { data } = await $host.get("api/main_banner");
    return data;
};

export const fetchOneMainBanner = async (id) => {
    const { data } = await $host.get("api/main_banner/" + id);
    return data;
};

export const removeOneMainBanner = async (id) => {
    const { data } = await $host.post("api/main_banner/" + id);
    return data;
};

export const updateOneMainBanner = async (processData, id) => {
    const { data } = await $host.post("api/main_banner/u/" + id, processData);
    return data;
};

export const createMayorMessage = async (mayorMessage) => {
    const { data } = await $authHost.post("api/message", mayorMessage);
    return data;
};

export const fetchMayorMessage = async () => {
    const { data } = await $host.get("api/message");
    return data;
};

export const fetchOneMayorMessage = async (id) => {
    const { data } = await $host.get("api/message/" + id);
    return data;
};

export const removeOneMayorMessage = async (id) => {
    const { data } = await $host.post("api/message/" + id);
    return data;
};

export const updateOneMayorMessage = async (processData, id) => {
    const { data } = await $host.post("api/message/u/" + id, processData);
    return data;
};

export const createCitizensHearingProgram = async (citizensHearingProgram) => {
    const { data } = await $authHost.post("api/citizens_hearing_program", citizensHearingProgram);
    return data;
};

export const fetchCitizensHearingProgram = async () => {
    const { data } = await $host.get("api/citizens_hearing_program");
    return data;
};

export const fetchOneCitizensHearingProgram = async (id) => {
    const { data } = await $host.get("api/citizens_hearing_program/" + id);
    return data;
};

export const removeOneCitizensHearingProgram = async (id) => {
    const { data } = await $host.post("api/citizens_hearing_program/" + id);
    return data;
};

export const updateOneCitizensHearingProgram = async (processData, id) => {
    const { data } = await $host.post("api/citizens_hearing_program/u/" + id, processData);
    return data;
};

export const createProjects = async (projects) => {
    const { data } = await $authHost.post("api/projects", projects);
    return data;
};

export const fetchProjects = async () => {
    const { data } = await $host.get("api/projects");
    return data;
};

export const fetchOneProjects = async (id) => {
    const { data } = await $host.get("api/projects/" + id);
    return data;
};

export const removeOneProjects = async (id) => {
    const { data } = await $host.post("api/projects/" + id);
    return data;
};

export const updateOneProjects = async (processData, id) => {
    const { data } = await $host.post("api/projects/u/" + id, processData);
    return data;
};

export const removeOneProjectsFile = async (id) => {
    const { data } = await $host.post("api/projects/rD/" + id);
    return data;
};
