import React, { useContext } from "react";
import { Image } from "react-bootstrap";
// import gov_logo from "../assets/gov_logo.png";
import dev_logo from "../assets/it-lab_logo_white.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const Footer = observer(() => {
    const { contactInformation } = useContext(Context);

    return (
        <>
            <footer className="d-flex flex-column flex-wrap justify-content-around bg-primary text-light	">
                {contactInformation.contactInformation ? (
                    <div className="upper-level d-flex flex-xl-row flex-column justify-content-around px-3 align-items-center">
                        <div className="d-flex flex-column align-items-center justify-content-center pt-3 pt-xl-0">
                            <Image
                                style={{ width: 100, height: "auto" }}
                                src={process.env.REACT_APP_API_URL + contactInformation.contactInformation.org_logo}
                            />
                            <h4 className="py-2 font-3">{contactInformation.contactInformation.org_name}</h4>
                        </div>
                        <div className="footer-links d-flex flex-column justify-content-center px-5">
                            <Link
                                to={contactInformation.contactInformation.youtube_link}
                                className="d-flex flex-row align-items-center justify-content-center py-2"
                            >
                                <i className="bi bi-youtube pe-3 icon-3" />
                                <span className="ps-2">YOUTUBE</span>
                            </Link>
                            <Link
                                to={contactInformation.contactInformation.facebook_link}
                                className="d-flex flex-row align-items-center justify-content-center py-2"
                            >
                                <i className="bi bi-facebook pe-3 icon-3" />
                                <span>FACEBOOK</span>
                            </Link>
                            <Link
                                to={contactInformation.contactInformation.telegram_link}
                                className="d-flex flex-row align-items-center justify-content-center py-2"
                            >
                                <i className="bi bi-telegram pe-3 icon-3" />
                                <span>TELEGRAM</span>
                            </Link>
                        </div>
                        <div className="footer-links d-flex flex-column justify-content-center">
                            <Link to={"#"} className="d-flex flex-row align-items-center justify-content-center py-2">
                                <i className="bi bi-diagram-3-fill pe-4 icon-3" />
                                <span className="ps-3">Harta site-ului</span>
                            </Link>
                            <div className="d-flex flex-row align-items-center justify-content-center py-2">
                                <i className="bi bi-envelope pe-3 icon-3" />
                                <span className="ps-3">
                                    {contactInformation.contactInformation.org_email || "Test Inforation"}
                                </span>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-center py-2">
                                <i className="bi bi-telephone pe-3 icon-3" />
                                <span className="ps-3">
                                    {contactInformation.contactInformation.org_phone || "Test Inforation"}
                                </span>
                            </div>
                        </div>
                        <div className="footer-links d-flex flex-column mt-5 mt-xl-0">
                            <div className="d-flex flex-column py-2">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <i className="bi bi-geo-alt icon-3" />
                                    <span className="ps-3 fw-bold font-1">Adresa</span>
                                </div>
                                <div className="footer-info-footer d-flex flex-column text-center">
                                    <span className="ps-3 text-wrap">
                                        {contactInformation.contactInformation.org_address || "Test Inforation"}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center flex-column py-2 text-center">
                                <div className="d-flex justify-content-center flex-row align-items-center">
                                    <i className="bi bi-clock icon-3" />
                                    <span className="ps-3 fw-bold font-1">Program de lucru:</span>
                                </div>
                                <span className="ps-3">
                                    {contactInformation.contactInformation.org_schedule || "Test Inforation"}
                                </span>
                            </div>
                        </div>
                        <div className="footer-links d-flex flex-column mt-5 mt-xl-0">
                            <div className="d-flex flex-row justify-content-center py-2">
                                <i className="bi bi-newspaper icon-3" />
                                <div className="d-flex flex-column">
                                    <div className="footer-info-header d-flex flex-row">
                                        <span className="ps-3 fw-bold font-1">Abonează-te</span>
                                    </div>
                                    <div className="footer-info-footer d-flex flex-column ">
                                        <div
                                            role="button"
                                            onClick={() => alert("Subscribed! DEMO")}
                                            className="ps-3 text-decoration-underline font-1"
                                        >
                                            la newsletter-ul nostru
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row py-md-2 align-items-center">
                                <i className="bi bi-shield-shaded pe-3 icon-3" />
                                <div className="footer-info-footer d-flex flex-column">
                                    <div className="footer-info-header justify-content-center d-flex flex-row">
                                        <span className="ps-3 fw-bold font-1">Linia Verde Antitrafic</span>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        {/* <i className='bi bi-envelope pe-3 icon-2' /> */}
                                        <span>
                                            {contactInformation.contactInformation.org_redline_email ||
                                                "Test Inforation"}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        {/* <i className='bi bi-telephone pe-3 icon-2' /> */}
                                        <span>
                                            {contactInformation.contactInformation.org_redline_phone ||
                                                "Test Inforation"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="web-site-info lower-level py-2 px-4 text-info mt-5">
                    <div className="d-flex flex-xl-row flex-column align-items-center justify-content-around text-center">
                        {contactInformation.contactInformation ? (
                            <span className="my-0 font-1">{contactInformation.contactInformation.org_copyright}</span>
                        ) : null}
                        <Link
                            to="https://it-labmd.com/"
                            target="_blank"
                            className="d-flex text-decoration-none text-white align-items-center justify-content-center"
                        >
                            <span className="pe-3 font-1">Dezvoltat de IT-Lab</span>
                            <Image style={{ height: 40, width: 40 }} src={dev_logo} />
                        </Link>
                        {contactInformation.contactInformation ? (
                            <span className="my-0 font-1">{contactInformation.contactInformation.org_updated}</span>
                        ) : null}
                    </div>
                </div>
            </footer>
        </>
    );
});

export default Footer;
