import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { createCitizensHearingProgram } from "../../../http/activitiesAPI";

const AddCitizensHearingProgramModal = ({ show, onHide }) => {
    const [role, setRole] = useState("");
    const [full_name, setFullName] = useState("");
    const [location, setLocation] = useState("");
    const [phone, setPhone] = useState("");
    //   const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");
    const [img, setImg] = useState([null]);
    const [days, setDays] = useState("");
    const [time, setTime] = useState("");

    const addCitizensHearingProgram = () => {
        const formData = new FormData();
        formData.append("role", role);
        formData.append("full_name", full_name);
        formData.append("location", location);
        formData.append("phone", phone);
        // formData.append("fax", fax);
        formData.append("email", email);
        formData.append("img", img);
        formData.append("days", days);
        formData.append("time", time);
        createCitizensHearingProgram(formData).then(() => window.location.reload());
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    return (
        <Modal show={show} onHide={onHide} className="add-news-modal border-primary">
            <Modal.Header closeButton={onHide} className="bg-primary" closeVariant="white">
                <Modal.Title className="text-white">Adauga o referita</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                    <span className="text-primary  font-2 py-2 ps-1">Poziția</span>
                    <Form.Control
                        aria-label="Title"
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Nume Complet</span>
                    <Form.Control
                        aria-label="Full Name"
                        value={full_name}
                        onChange={(e) => {
                            setFullName(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                    <Form.Control
                        type={"file"}
                        onChange={selectFile}
                        aria-label="PageTitle"
                        aria-describedby="basic-addon"
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Locație</span>
                    <Form.Control
                        aria-label="Location"
                        value={location}
                        onChange={(e) => {
                            setLocation(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Telefon</span>
                    <Form.Control
                        aria-label="Phone Number"
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Email</span>
                    <Form.Control
                        type="email" // Specify email type for validation
                        aria-label="Email Address"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Zile de recepție</span>
                    <Form.Control
                        aria-label="Days"
                        value={days}
                        onChange={(e) => {
                            setDays(e.target.value);
                        }}
                    />
                    <span className="text-primary  font-2 py-2 ps-1">Ore de recepție</span>
                    <Form.Control
                        aria-label="Time"
                        value={time}
                        onChange={(e) => {
                            setTime(e.target.value);
                        }}
                    />
                    {/* <span className="text-primary  font-2 py-2 ps-1">Fax (Optional)</span>
          <Form.Control
            aria-label="Fax Number"
            value={fax}
            onChange={(e) => {
              setFax(e.target.value);
            }}
          /> */}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        onHide();
                        addCitizensHearingProgram();
                    }}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCitizensHearingProgramModal;
