import { observer } from "mobx-react-lite";
import { useContext, useState, useEffect } from "react";
import { Spinner, Card } from "react-bootstrap";
import { Context } from "..";
import { Link } from "react-router-dom";
import { LAST_NEWS_ROUTE } from "../utils/consts";
import { fetchLastNews } from "../http/NewsAPI";
import parse from "html-react-parser";

const LastNews = observer(() => {
  const { lastNews } = useContext(Context);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchLastNews()
      .then((data) => {
        lastNews.setLastNews(data);
      })
      .finally(() => setLoading(false));
  }, [lastNews]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <Spinner animation={"grow"} />
      </div>
    );
  }
  return (
    <div className="px-xl-5 px-2 border-bottom">
      <div className="py-4 block-title">
        <span>Știri de ultimă oră</span>
      </div>
      <div className="d-flex justify-content-around flex-xl-row flex-column">
        {lastNews.lastNews.count > 0
          ? lastNews.lastNews.rows.map((item, idx) => {
              if (idx < 4)
                return (
                  <Card
                    className="shadow-sm h-100 border-0 my-xl-2 mx-xl-3 my-4"
                    key={idx}
                  >
                    {/* <Card.Img variant='top' src='https://picsum.photos/160/100' /> */}
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <Link
                        to={LAST_NEWS_ROUTE + "/" + item.id}
                        className="text-decoration-none text-dark"
                      >
                        <Card.Title className="text-primary truncate">
                          {item.title}
                        </Card.Title>
                      </Link>
                      <Card.Text className="truncate">
                        {parse(item.article)}
                      </Card.Text>
                      <Link
                        className="text-white px-3 py-2 bg-primary text-decoration-none rounded text-center font-1"
                        to={LAST_NEWS_ROUTE + "/" + item.id}
                      >
                        Citește mai mult...
                      </Link>
                    </Card.Body>
                  </Card>
                );
            })
          : null}
      </div>
      <div className="d-flex justify-content-center my-4">
        <Link
          className="bg-primary text-decoration-none text-white py-2 px-3 rounded d-flex align-items-center font-2"
          to={LAST_NEWS_ROUTE}
        >
          Vezi mai mult <i className="bi bi-arrow-right ps-3 icon-2" />
        </Link>
      </div>
    </div>
  );
});

export default LastNews;
