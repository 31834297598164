import { useContext, useState, useEffect } from "react";
import { Spinner, Card, Accordion, Form, useAccordionButton, Image } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import parse from "html-react-parser";
import { EUROPEAN_PROJECTS_ROUTE } from "../../utils/consts";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import AddProjectsModal from "./Modals/AddProjectsModal";
import { fetchProjects, removeOneProjects, removeOneProjectsFile, updateOneProjects } from "../../http/activitiesAPI";

const ProjectsAdmin = observer(() => {
    const navigate = useNavigate();
    const { activities } = useContext(Context);
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState([null]);
    const [files, setFiles] = useState([null]);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchProjects()
            .then((data) => {
                activities.setProjects(data);
            })
            .finally(() => setLoading(false));
    }, []);

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const removeProject = (item) => {
        removeOneProjects(item.id).then(() => window.location.reload());
    };

    const removeItemFile = (item) => {
        const id = item.id;
        removeOneProjectsFile(id).then(() => window.location.reload());
    };

    const editProject = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("img", img);
        for (const file of files) {
            formData.append("files", file);
        }
        updateOneProjects(formData, id).then(() => window.location.reload());
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    const selectMultipleFiles = (e) => {
        const files = Array.from(e.target.files);
        setFiles(files);
    };

    const modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image"],
            [
                {
                    color: [
                        "#000000",
                        "#e60000",
                        "#ff9900",
                        "#ffff00",
                        "#008a00",
                        "#0066cc",
                        "#9933ff",
                        "#ffffff",
                        "#facccc",
                        "#ffebcc",
                        "#ffffcc",
                        "#cce8cc",
                        "#cce0f5",
                        "#ebd6ff",
                        "#bbbbbb",
                        "#f06666",
                        "#ffc266",
                        "#ffff66",
                        "#66b966",
                        "#66a3e0",
                        "#c285ff",
                        "#888888",
                        "#a10000",
                        "#b26b00",
                        "#b2b200",
                        "#006100",
                        "#0047b2",
                        "#6b24b2",
                        "#444444",
                        "#5c0000",
                        "#663d00",
                        "#666600",
                        "#003700",
                        "#002966",
                        "#3d1466",
                        "custom-color",
                    ],
                },
            ],
        ],
    };

    const formats = [
        "header",
        "height",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "color",
        "bullet",
        "indent",
        "link",
        "image",
        "align",
        "size",
    ];

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={"grow"} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Proiecte</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="px-5 mt-4" style={{ maxHeight: 1080, overflow: "auto" }}>
                <Accordion className="pb-4">
                    {activities.projects.count > 0
                        ? activities.projects.rows.map((item, idx) => (
                              <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                  <div className="d-flex flex-column">
                                      <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
                                          <div className="d-flex flex-xl-row flex column align-items-center">
                                              {item.img ? (
                                                  <Image
                                                      className="rounded"
                                                      src={process.env.REACT_APP_API_URL + item.img}
                                                      style={{ maxHeight: "30vh" }}
                                                  />
                                              ) : null}
                                              <div className="d-flex flex-column  ps-xl-4">
                                                  <a
                                                      role="button"
                                                      onClick={() => navigate(EUROPEAN_PROJECTS_ROUTE + "/" + item.id)}
                                                      className="text-decoration-none text-primary"
                                                  >
                                                      <Card.Title className="truncate">{item.title}</Card.Title>
                                                  </a>
                                                  {item.description ? (
                                                      <div className="truncate">{parse(item.description)}</div>
                                                  ) : null}
                                              </div>
                                          </div>
                                          <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                              <CustomToggle
                                                  eventKey={idx}
                                                  onClick={() => {
                                                      useAccordionButton({ idx });
                                                  }}
                                              >
                                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                                  <span>Edit</span>
                                              </CustomToggle>
                                              <button
                                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                  onClick={() => removeProject(item)}
                                              >
                                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                                  <span>Remove</span>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                                  <Accordion.Collapse eventKey={idx}>
                                      <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                          <div className="d-flex flex-column justify-content-around px-4 py-4">
                                              <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                                              <Form.Control
                                                  aria-label="Title"
                                                  placeholder={item.title}
                                                  value={title}
                                                  onChange={(e) => {
                                                      setTitle(e.target.value);
                                                  }}
                                              />
                                              <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
                                              <ReactQuill
                                                  theme="snow"
                                                  placeholder={item.description}
                                                  value={description}
                                                  modules={modules}
                                                  formats={formats}
                                                  onChange={setDescription}
                                              />
                                              <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                                              <Form.Control
                                                  type={"file"}
                                                  onChange={selectFile}
                                                  aria-label="PageTitle"
                                                  aria-describedby="basic-addon"
                                              />
                                              <span className="text-primary  font-2 py-2 ps-1">Documente</span>
                                              <Form.Control
                                                  type={"file"}
                                                  onChange={selectMultipleFiles}
                                                  aria-label="PageTitle"
                                                  aria-describedby="basic-addon"
                                                  multiple
                                              />
                                              {item.project_files.length > 0
                                                  ? item.project_files.map((sub_item, sub_idx) => (
                                                        <div
                                                            key={sub_idx}
                                                            className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <i
                                                                    className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                                />
                                                                <a
                                                                    href={
                                                                        process.env.REACT_APP_API_URL +
                                                                        "/" +
                                                                        sub_item.doc
                                                                    }
                                                                >
                                                                    <span
                                                                        className="text-wrap"
                                                                        style={{ textAlign: "justify" }}
                                                                    >
                                                                        {sub_item.doc.split(".")[0]}
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => removeItemFile(sub_item)}
                                                            >
                                                                <i className="bi bi-x-circle font-2"></i>
                                                            </button>
                                                        </div>
                                                    ))
                                                  : null}
                                          </div>
                                          <button
                                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                              onClick={() => editProject(item)}
                                          >
                                              <i className="bi bi-check icon-2 pe-2" />
                                              <span>Confirm</span>
                                          </button>
                                      </div>
                                  </Accordion.Collapse>
                              </div>
                          ))
                        : null}
                </Accordion>

                {/* <div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descrieree</span>
					<ReactQuill
						theme='snow'
						value={description}
						modules={modules}
						formats={formats}
						onChange={setDescription}
					/>
				</div> */}
            </div>
            <AddProjectsModal show={show} onHide={() => setShow(false)} />
        </div>
    );
});

export default ProjectsAdmin;
