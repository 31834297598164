import { useState, useEffect, useRef } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BIOGRAPHY_ROUTE } from "../utils/consts";
import { fetchBiography } from "../http/navLinksAPI";
import { Spinner } from "react-bootstrap";
import ShareButton from "../components/UI/ShareButton";

const Management = () => {
	const navigate = useNavigate();
	const [servicesResp, setServicesResp] = useState();
	const [loading, setLoading] = useState(true);

	const printRef = useRef(null);
	const handlePrint = () => {
		const originalBodyContent = document.body.innerHTML;
		document.body.innerHTML = printRef.current.outerHTML;
		window.print();

		onafterprint = (e) => {
			console.log(e);

			document.body.innerHTML = originalBodyContent;
		};
		window.location.reload();
	};

	useEffect(() => {
		fetchBiography()
			.then((data) => {
				setServicesResp(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className='d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2'>
				<div
					className='d-flex flex-column col-xl-8 px-xl-4'
					ref={printRef}>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Conducerea</span>
					</div>
					<Table
						bordered
						hover>
						<thead>
							<tr>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									Numele, Prenumele
								</th>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									Funcția
								</th>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white'>
									Numărul de telefon
								</th>
								<th
									style={{ verticalAlign: "middle" }}
									className='bg-primary text-white text-center'>
									CV
								</th>
							</tr>
						</thead>
						{servicesResp.count > 0
							? servicesResp.rows.map((item, idx) => (
									<tbody>
										<tr>
											<td style={{ verticalAlign: "middle" }}>
												{item.last_name} {item.first_name}
											</td>
											<td style={{ verticalAlign: "middle" }}>{item.role}</td>
											<td style={{ verticalAlign: "middle" }}>{item.phone}</td>
											<td
												style={{ verticalAlign: "middle" }}
												className='bg-white text-white text-center'
												role='button'
												onClick={() => navigate(BIOGRAPHY_ROUTE + "/" + item.id)}>
												<i className='bi text-primary bi-file-earmark-text icon-2' />
											</td>
										</tr>
									</tbody>
							  ))
							: null}
					</Table>
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-end w-100'>
						<i
							role='button'
							onClick={() => handlePrint()}
							className='bi bi-printer text-primary icon-3 px-4'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<ShareButton />
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Management;
